import React from 'react'
import { Outlet, BrowserRouter, Route, Router, Routes, useNavigate, Navigate } from 'react-router-dom'



import Menu from './components/fragments/Menu'
import Login from './components/pages/Login'
import Register from './components/pages/Register'
import Stock from './components/pages/Stock'
import History from './components/pages/History'

import { Container } from '@mui/system'
import StockCreate from './components/pages/StockCreate'
import StockEdit from './components/pages/StockEdit'
import { useDispatch, useSelector } from 'react-redux'

// relogin
import * as loginAction from './actions/login.action'
import * as loginReducer from './reducers/login.reducer'
import EquipmentMigration from './components/pages/EquipmentMigration'
// state management

// secured route

const PrivateRoute = ({ isLoggedIn, children }) => {
    if (isLoggedIn) {
        return children
    } else {
        return (<Navigate to="/login" />)

    }
}

const LoginHomePage = ({ isLoggedIn }) => {
    if (isLoggedIn) {
        return (<Navigate to="/stock" />)
    } else {
        return (
            <Login />
        )

    }
}




export default function App() {

    const loginReducer = useSelector(({ loginReducer }) => loginReducer)
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(loginAction.reLogin())
    }, [])


    return (
        <BrowserRouter style={{ display: "flex", flex: 1, }}>
            {loginReducer.result && <Menu />}
            {console.log("loginReducer.result => ", loginReducer.result)}
            <Container maxWidth="xl" style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingTop: 100 }}>
                <Routes >
                    <Route path="/" element={<Navigate to="/login" />} exact={true} />
                    <Route path="*" element={<Navigate to="/login" />} exact={true} />
                    <Route path="/login" element={
                        <LoginHomePage isLoggedIn={loginAction.isLoggedIn()}>
                            <Login />
                        </LoginHomePage>
                    } />
                    <Route path="/register" element={<Register />} />
                    <Route path="/stock" element={
                        <PrivateRoute isLoggedIn={loginAction.isLoggedIn()} >
                            <Stock />
                        </PrivateRoute>
                    } />
                    <Route path="/stock-create" element={
                        <PrivateRoute isLoggedIn={loginAction.isLoggedIn()} >
                            <StockCreate />
                        </PrivateRoute>
                    } />
                    <Route path="/stock-edit/:id" element={
                        <PrivateRoute isLoggedIn={loginAction.isLoggedIn()} >
                            <StockEdit />
                        </PrivateRoute>
                    } />
                    <Route path="/history" element={
                        <PrivateRoute isLoggedIn={loginAction.isLoggedIn()} >
                            <History />
                        </PrivateRoute>
                    } />
                    <Route path="/equipment-migration" element={
                        <PrivateRoute isLoggedIn={loginAction.isLoggedIn()} >
                            <EquipmentMigration />
                        </PrivateRoute>
                    } />


                </Routes>
            </Container>

        </BrowserRouter>
    )
}
