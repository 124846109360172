import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as historyACtion from '../../actions/history.action'


export default function Stock() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const historyReducer = useSelector(({ historyReducer }) => historyReducer)
    const [selectionItem, setSelectionItem] = React.useState([])
    const [needFetching, setNeedFetching] = React.useState(false)


    React.useEffect(() => {
        dispatch(historyACtion.getHistory())
        setNeedFetching(false)

    }, [needFetching])




    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'project_name', headerName: 'project_name', width: 130, minWidth: 300 },
        { field: 'serial_number', headerName: 'serial_number', width: 150 },
        { field: 'borrower', headerName: 'borrower', width: 130, minWidth: 300 },
        { field: 'borrower_date', headerName: 'borrower_date', width: 200},
        { field: 'back_to_store_date', headerName: 'back_to_store_date', width: 200 },
        { field: 'status_equipment_back', headerName: 'status_equipment_back', width: 200 },
    ];


    return (

        <div style={{ width: 2500 }} >

            <div style={{ height: 700, width: 1750, borderRadius: 10, boxSizing: 0 }}>
                <DataGrid
                    style={{ backgroundColor: "#ffffff" }}
                    rows={historyReducer.result ? historyReducer.result.data : []}
                    columns={columns}
                    rowsPerPageOptions={[10, 20, 40, 100]}
                    columnWidth="*"
                    components={{ Toolbar: GridToolbar }}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'desc',
                                },
                            ],
                        }
                    }}

                />
            </div>
        </div>
    );
}
