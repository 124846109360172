import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, TextField, Container } from '@mui/material';



// dialog warning
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



import * as loginAction from './../../actions/login.action'
import { useDispatch, useSelector } from 'react-redux';

export default function Login(props) {
    const navigate = useNavigate();
    const [account, setAccount] = React.useState({ username: "", password: "" })
    const dispatch = useDispatch()

    const [dialogHandle, setDialogHandle] = React.useState(false)
    const loginReducer = useSelector(({ loginReducer }) => loginReducer);

    const dialogLoginHandle = () => {
        setDialogHandle(false)
        dispatch(loginAction.setStateLogout())
    }


    function showDialog() {
        return (
            <Dialog
                open={loginReducer.error}
                onClose={dialogLoginHandle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Login status"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ width: 250 }}>
                        Login Failed
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dialogLoginHandle} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>)
    }

    return (
        <Card sx={{ maxWidth: 350 }} style={{  height: 470, width: 320, display: "flex", flexDirection: "column" }} >
            <CardMedia
                component="img"
                alt="green iguana"
                height="160"
                image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
            />
            <Box fullWidth style={{ padding: 5 , margin: 10}} >
                <Typography style={{ padding: 7 }} variant='h5' > Login</Typography>
                <form noValidate onSubmit={e => {
                    e.preventDefault()
                    dispatch(loginAction.login({ ...account, navigate }))
                }} >
                    <TextField fullWidth style={{ marginRight: 4,paddingBottom: 20}} value={account.username} onChange={
                        e => {
                            setAccount({
                                ...account,
                                username: e.target.value
                            })
                        }
                    } id="username" label="Username" variant="outlined" />
                    <TextField fullWidth style={{  marginRight: 4, paddingRight: 4 }} value={account.password} onChange={
                        e => {
                            setAccount({
                                ...account,
                                password: e.target.value
                            })
                        }
                    } id="password" type="password" label="Password" variant="outlined" />
                    <Button fullWidth style={{ padding: 4, marginTop: 30 }} type="submit" variant="contained" color="primary"  >Login</Button>
                    <Button fullWidth style={{ padding: 4 }} onClick={() => {
                        navigate("/register")
                    }} >Register</Button>
                </form>

            </Box>
            {showDialog()}
        </Card>

    );
}


