import React from 'react'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography'

import { useNavigate } from 'react-router-dom';

// import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { Formik } from 'formik';

// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';
import * as stockAction from '../../actions/stock.action'

function getParams(params) {
    console.log("params =>", params)
}




export default function EquipmentMigration() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const stockReducer = useSelector(({ stockReducer }) => stockReducer)
    const [selectionItem, setSelectionItem] = React.useState([])
    const [selectionStatusItem, setSelectionStatusItem] = React.useState([])

    const [needFetching, setNeedFetching] = React.useState(false)

    // drop down status
    const [statusEquipment, setStatusEquipment] = React.useState('');
    const handleChangeStatusEquipment = (event) => {
        setStatusEquipment(event.target.value);
    };

    React.useEffect(() => {
        dispatch(stockAction.getProducts())
        setNeedFetching(false)

    }, [needFetching])





    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'item_name', headerName: 'item_name', width: 130, minWidth: 300 },
        { field: 'category', headerName: 'category', width: 130 },
        { field: 'brand', headerName: 'brand', width: 130 },
        { field: 'model', headerName: 'model', width: 130 },
        { field: 'serial_number', headerName: 'serial_number', width: 130 },
        { field: 'project_name', headerName: 'project_name', width: 130 },
        { field: 'status', headerName: 'status', width: 130 },
        { field: 'borrower', headerName: 'borrower', width: 130, minWidth: 300 },
        { field: 'pv_number', headerName: 'pv_number', width: 130 },
        { field: 'po_number', headerName: 'po_number', width: 130 },
    ];


    function showForm({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting
    }) {
        return (
            <form noValidate onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <TextField
                    required
                    fullWidth
                    value={values.project_name}
                    onChange={handleChange}
                    style={{ margin: 10, width: 420 }}
                    id="project_name"
                    name="project_name"
                    label="project_name"
                    variant="outlined"
                    inputProps={{
                        form: {
                            autocomplete: 'off'
                        }
                    }}

                />

                <TextField
                    required
                    value={values.borrower}
                    onChange={handleChange}
                    style={{ margin: 10, width: 420 }}
                    id="borrower"
                    name="borrower"
                    type="borrower"
                    label="borrower"
                    variant="outlined"
                    inputProps={{
                        form: {
                            autocomplete: 'off'
                        }
                    }}

                />

                <FormControl sx={{ margin: 2, width: 420 }} size="small">
                    <InputLabel id="demo-select-small">Status</InputLabel>
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={statusEquipment}
                        label="Status"
                        onChange={handleChangeStatusEquipment}
                    >
                        <MenuItem value={'Stock'}>Stock</MenuItem>
                        <MenuItem value={'Borrow'}>Borrow</MenuItem>
                        <MenuItem value={'Cut off'}>Cut off</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    fullWidth
                    style={{ margin: 50, width: 420, height: 40 }}
                    variant="contained"
                    color="success"
                    type='submit'
                    disabled={isSubmitting}
                >Confirm to move</Button>

            </form>)
    }

    return (
        <div style={{ display: 'flex', alignItem: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Card style={{ height: 800, width: 1750, margin: 20 }}>
                <Typography variant="h5" color="initial" style={{}} > Equipment List for transfer</Typography>
                <DataGrid
                    style={{ backgroundColor: "#ffffff", height: 750 }}
                    rows={stockReducer.result ? stockReducer.result.data : []}
                    columns={columns}
                    rowsPerPageOptions={[10, 20, 40, 100]}
                    checkboxSelection
                    columnWidth="*"
                    components={{ Toolbar: GridToolbar }}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        }
                    }}
                    onSelectionModelChange={(selectionId) => {
                        setSelectionItem(selectionId)
                    }}

                />
            </Card>
            { (selectionItem.length > 0) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                <Card style={{ height: 380, width: 450, margin: 20, }}>
                    <Typography variant="h5" color="initial" style={{ margin: 5, textAlign: 'center' }} >Move to</Typography>
                    <Formik
                        initialValues={{ project_name: "", borrower: "" }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            dispatch(stockAction.moveProducts(selectionItem, { ...values, status: statusEquipment }))
                            setSubmitting(false);
                            setNeedFetching(true)

                        }
                        }
                    >{props =>   showForm(props)}</Formik>
                </Card>
            </div> }

        </div>
    )
}
