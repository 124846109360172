import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// state management
import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import { Provider } from 'react-redux';
import reducers from "./reducers"
// console.log("reducers => ", reducers)


// import { composeWithDevTools } from "redux-devtools-extension";


// import thunk from "redux-thunk"
// import logger from "redux-logger"

// let middlewares = applyMiddleware(thunk)
const store = configureStore({
    reducer: reducers,
})
// composeWithDevTools(middlewares)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
