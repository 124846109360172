import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';


import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import { Formik } from 'formik';
import { Box, TextField } from '@mui/material';



import { server } from '../../constants';
import axios from 'axios'
import { Container } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

import * as stockAction from './../../actions/stock.action'


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function StockCreate() {
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const [isStockCreate, setIsStockCreate] = React.useState(false)


    const dialogStockCreateHandle = () => {
        setIsStockCreate(false)
    }

    function showForm({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting
    }) {
        return (
            <form noValidate onSubmit={handleSubmit} style={{}} >
                <Container style={{ display: 'flex', flexDirection: 'row' }} >
                    {/* item_name  category  brand  model  serial_number */}
                    <Box style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 1 }}>
                        <TextField
                            required
                            value={values.username}
                            onChange={handleChange}
                            style={{ marginRight: 4, flex: 1, paddingBottom: 15 }}
                            id="item_name"
                            name="item_name"
                            label="item_name"
                            variant="standard" />
                        <TextField
                            required
                            value={values.category}
                            onChange={handleChange}
                            style={{ marginRight: 4, flex: 1, paddingBottom: 15 }}
                            id="category"
                            name="category"
                            label="category"
                            variant="standard" />
                        <TextField
                            required
                            value={values.brand}
                            onChange={handleChange}
                            style={{ marginRight: 4, flex: 1, paddingBottom: 15 }}
                            id="brand"
                            name="brand"
                            label="brand"
                            variant="standard" />
                        <TextField
                            required
                            value={values.model}
                            onChange={handleChange}
                            style={{ marginRight: 4, flex: 1, paddingBottom: 15 }}
                            id="model"
                            name="model"
                            label="model"
                            variant="standard" />
                        <TextField
                            required
                            value={values.serial_number}
                            onChange={handleChange}
                            style={{ marginRight: 4, flex: 1, paddingBottom: 15 }}
                            id="serial_number"
                            name="serial_number"
                            label="serial_number"
                            variant="standard" />
                    </Box>


                    {/*  status   borrower   project_name   pv_number   po_number    */}

                    <Box style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 1 }} >
                        <TextField
                            required
                            value={values.status}
                            onChange={handleChange}
                            style={{ marginRight: 4, flex: 1, paddingBottom: 15 }}
                            id="status"
                            name="status"
                            label="status"
                            variant="standard" />
                        <TextField
                            required
                            value={values.borrower}
                            onChange={handleChange}
                            style={{ marginRight: 4, paddingBottom: 20, flex: 1, paddingBottom: 15 }}
                            id="borrower"
                            name="borrower"
                            label="borrower"
                            variant="standard" />
                        <TextField
                            required
                            value={values.project_name}
                            onChange={handleChange}
                            style={{ marginRight: 4, flex: 1, paddingBottom: 15 }}
                            id="project_name"
                            name="project_name"
                            label="project_name"
                            variant="standard" />
                        <TextField
                            required
                            value={values.pv_number}
                            onChange={handleChange}
                            style={{ marginRight: 4, paddingBottom: 20, flex: 1, paddingBottom: 15 }}
                            id="pv_number"
                            name="pv_number"
                            label="pv_number"
                            variant="standard" />
                        <TextField
                            required
                            value={values.po_number}
                            onChange={handleChange}
                            style={{ marginRight: 4, paddingBottom: 20, flex: 1, paddingBottom: 15 }}
                            id="po_number"
                            name="po_number"
                            label="po_number"
                            variant="standard" />
                    </Box>
                </Container>


                {/* {registerError && <Alert severity="error">Existing Account!</Alert>} */}
                <Typography>
                    <Button
                        style={{ padding: 4, marginRight: 15, width: 80, height: 40 }}
                        variant="contained"
                        color="success"
                        type='submit'
                    // disabled={isSubmitting}
                    >Create</Button>
                    <Button style={{ padding: 4, width: 80, height: 40 }} onClick={() => {
                        navigate(-1)
                    }} >Back</Button>
                </Typography>
            </form>)
    }

    function showDialog() {
        return (
            <Dialog
                open={isStockCreate}
                onClose={dialogStockCreateHandle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Stock Create Status"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ width: 250 }}>
                        Create Equipment Succesful
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dialogStockCreateHandle} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>)
    }

    return (
        <Card sx={{ minWidth: 275, width: 800 }}>
            <Typography variant="h4" style={{ margin: 15, }}>
                Stock Create
            </Typography>
            <CardContent>
                <Formik
                    initialValues={{
                        item_name: "", category: "", brand: "", model: "", serial_number: "",
                        status: "Stock", borrower: "-", project_name: "-", pv_number: "", po_number: ""
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        try {
                            dispatch(stockAction.createProducts(values))
                            setIsStockCreate(true)
                        } catch (error) {

                        }
                        setSubmitting(false)

                    }
                    }
                >{props => showForm(props)}</Formik>
            </CardContent>
            {showDialog()}
           
        </Card>
    );
}
