import { useDispatch } from "react-redux"
import axios from 'axios'
import { HISTORY_FAILED, HISTORY_FETCHING, HISTORY_SUCCESS, server, TOKEN } from "../constants"

const configsAxios = {
    headers: {
        "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`
    }
}


export const setStateToSuccess = (payload) => ({
    type: HISTORY_SUCCESS,
    payload
})

export const setStateToFetching = () => ({
    type: HISTORY_FETCHING,
})

export const setStateFailed = () => ({
    type: HISTORY_FAILED
})

export const getHistory = () => {
    return dispatch => {
        dispatch(setStateToFetching())
        doGetHistory(dispatch)
    }
}

export const doGetHistory = async dispatch => {
    try {
        let result = await axios.get(server.GET_HISTORY, configsAxios)
        dispatch(setStateToSuccess(result))
    } catch (error) {
        dispatch(setStateFailed())
    }
}
