import { useDispatch } from "react-redux"
import { LOGIN_FAILED, LOGIN_FETCHING, LOGIN_PASSED, LOGIN_STATUS, LOGIN_SUCCESS, LOGOUT, server, TOKEN } from "../constants"

import axios from 'axios'




export const setStateFetching = () => ({
    type: LOGIN_FETCHING,
})

export const setStateSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload
})

export const setStateFailed = (payload) => ({
    type: LOGIN_FAILED,
    payload
})

export const setStateLogout = () => ({
    type: LOGOUT
})




export const login = ({ username, password, navigate }) => {
    return async dispatch => {
        dispatch(setStateFetching())

        try {
            const result = await axios.post(server.LOGIN, { username, password })
            if (result.data.token) {
                console.log("Already login => ", result.data.token)
                localStorage.setItem(LOGIN_STATUS, "ok")
                localStorage.setItem(TOKEN, result.data.token)
                dispatch(setStateSuccess("ok"))
                navigate("/stock")
            }
        } catch (error) {
            localStorage.setItem(LOGIN_STATUS, null)
            dispatch(setStateFailed())
        }



    }
}

export const logout = ({ navigate }) => {
    return dispatch => {
        localStorage.removeItem(LOGIN_STATUS)
        dispatch(setStateLogout())
        navigate("/login")
    }
}

export const reLogin = () => {
    return dispatch => {
        const loginStatus = localStorage.getItem(LOGIN_STATUS)
        console.log("cache token => ", loginStatus)
        if (loginStatus === "ok") {
            dispatch(setStateSuccess("ok"))
        }
    }
}

export const isLoggedIn = () => {
    const isLogin = localStorage.getItem(LOGIN_STATUS)
    console.log("isLoggedIn checking =>", isLogin)
    return isLogin === "ok" ? true : false
}
