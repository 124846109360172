export const LOGIN_FETCHING = "LOGIN_FETCHING"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT = "LOGOUT"


export const LOGIN_STATUS = "LOGIN_STATUS"
export const TOKEN = "TOKEN"



// stock Page
export const STOCK_FETCHING = "STOCK_FETCHING"
export const STOCK_SUCCESS = "STOCK_SUCCESS"
export const STOCK_FAILED = "STOCK_FAILED"
export const STOCK_CLEAR = "STOCK_CLEAR"

// stock Edit Page
export const STOCK_EDIT_FETCHING = "STOCK_EDIT_FETCHING"
export const STOCK_EDIT_SUCCESS = "STOCK_EDIT_SUCCESS"
export const STOCK_EDIT_FAILED = "STOCK_EDIT_FAILED"
export const STOCK_EDIT_INITIALED = "STOCK_EDIT_INITIALED"


// history 
export const HISTORY_SUCCESS = "HISTORY_SUCCESS"
export const HISTORY_FETCHING =  "HISTORY_FETCHING "
export const HISTORY_FAILED = "HISTORY_FAILED"




// export const apiUrl = "https://demo-deploy.advance-e.net"



export const apiUrl = "https://stockapp.advance-e.net"
// export const apiUrl =  "http://localhost:4800"

// export const apiUrl = "https://demo-deploy.advance-e.net"

// const routes = {
//     ACCOUNT_URL: `${apiUrl}/demo`,
//     EQUIPMENT_URL: `${apiUrl}/equipments`,
// }

const routes = {
    ACCOUNT_URL: `${apiUrl}/account`,
    EQUIPMENT_URL: `${apiUrl}/equipments`,
    HISTORY_URL : `${apiUrl}/history`
}



export const server = {
    LOGIN  : `${routes.ACCOUNT_URL}/login`,
    REGISTER : `${routes.ACCOUNT_URL}/register`,
    GET_EQUIPMENT : `${routes.EQUIPMENT_URL}/`,
    ADD_EQUIPMENT : `${routes.EQUIPMENT_URL}/`,
    DEL_EQUIPMENT : `${routes.EQUIPMENT_URL}/`,
    MOVE_EQUIPMENT : `${routes.EQUIPMENT_URL}/move/`,
    GET_HISTORY : `${routes.HISTORY_URL}`
}
