import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import ListItemText from '@mui/material/ListItemText';
import {
    MoveToInbox as InboxIcon, MailIcon as Mail,
    Inventory 
} from '@mui/icons-material';
import * as MaterialIcon from '@mui/icons-material';

import Button from '@mui/material/Button';




// navigate
import { useNavigate, NavLink } from 'react-router-dom';



import * as loginAction from './../../actions/login.action'
import { useDispatch } from 'react-redux';



const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const DrawerCustom = styled(Drawer)({
    backgroundImage:"url(" + `${process.env.PUBLIC_URL}/images/background_menu.jpg` + ")"
})


const ListItemWithActive = styled(ListItem)({
    '&.active': {
        backgroundColor: "#e0f5fd",
        color: "#0080ff"
    },
    color: "black",

})

export default function Menu() {
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch()

    return (
        <div>
            <Box sx={{ display: 'flex', paddingBottom: 5, }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}  sx={{bgcolor: "#0031ca"}}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Menu
                        </Typography>
                        <Button color="inherit" onClick={() => {
                            dispatch(loginAction.logout({ navigate }))
                        }} >Logout</Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            backgroundImage: `url("${process.env.PUBLIC_URL}/images/background_menu.jpg")` 
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />

                    <List  >
                        <ListItemWithActive
                            key="Stock"
                            disablePadding
                            to="/stock"
                            component={NavLink}

                        >
                            <ListItemButton   >
                                <ListItemIcon>
                                    <Inventory />
                                </ListItemIcon>
                                <ListItemText primary="stock" />
                            </ListItemButton  >

                        </ListItemWithActive>

                        <ListItemWithActive
                            key="History"
                            disablePadding
                            to="/history"
                            component={NavLink}
                        >
                            <ListItemButton  >
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary="History" />
                            </ListItemButton>
                        </ListItemWithActive>

                        <ListItemWithActive
                            key="Equipment Migration"
                            disablePadding
                            to="/equipment-migration"
                            component={NavLink}
                        >
                            <ListItemButton  >
                                <ListItemIcon>
                                    <MaterialIcon.IosShare />
                                </ListItemIcon>
                                <ListItemText primary="Equipment migration" />
                            </ListItemButton>
                        </ListItemWithActive>
                    </List>

                </Drawer>
            </Box>


        </div >
    )
}
