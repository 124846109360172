import { STOCK_FAILED, STOCK_FETCHING, STOCK_SUCCESS, server, TOKEN } from '../constants'

import axios from 'axios'




const configsAxios = {
    headers: {
        "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`
    }
}





export const setStateToSuccess = (payload) => ({
    type: STOCK_SUCCESS,
    payload
})

export const setStateToFetching = () => ({
    type: STOCK_FETCHING,
})

export const setStateFailed = () => ({
    type: STOCK_FAILED
})


export const getProducts = () => {
    return dispatch => {
        dispatch(setStateToFetching())
        doGetProducts(dispatch)
    }
}

export const deleteProducts = (id) => {
    return dispatch => {
        dispatch(setStateToFetching())
        doDeleteProducts(dispatch, id)
    }
}

export const createProducts = (equipment) => {
    return dispatch => {
        dispatch(setStateToFetching())
        doCreateProducts(dispatch, equipment)
    }
}

export const moveProducts = (id, details) => {
    return dispatch => {
        dispatch(setStateToFetching())
        doMoveProducts(dispatch, id, details)
    }
}



export const doGetProducts = async dispatch => {
    try {
        let result = await axios.get(server.GET_EQUIPMENT, configsAxios)
        dispatch(setStateToSuccess(result))
    } catch (error) {
        dispatch(setStateFailed())
    }
}

export const doDeleteProducts = async (dispatch, id) => {
    try {
        if (id.length > 1) {
            id.forEach(async element => {
                await axios.delete(`${server.DEL_EQUIPMENT}${element}`, configsAxios)
            });
        } else {
            await axios.delete(`${server.DEL_EQUIPMENT}${id}`, configsAxios)
        }
        dispatch(setStateToSuccess())
    } catch (error) {
        dispatch(setStateFailed())

    }
}

export const doCreateProducts = async (dispatch, equipment) => {
    try {
        // do something 
        let result = await axios.post(server.ADD_EQUIPMENT, equipment, configsAxios)
        dispatch(setStateToSuccess(result))
    } catch (error) {
        dispatch(setStateFailed())

    }
}

export const doMoveProducts = async (dispatch, id, details) => {
    try {
        if (id.length > 1) {
            id.forEach(async element => {
                await axios.put(`${server.MOVE_EQUIPMENT}${element}`, details, configsAxios)
            });
        } else {
            await axios.put(`${server.MOVE_EQUIPMENT}${id}`, details,configsAxios)
        }
        dispatch(setStateToSuccess())
    } catch (error) {
        dispatch(setStateFailed())

    }
}