import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Box, TextField } from '@mui/material';
import { Formik } from 'formik';

import Alert from '@mui/material/Alert';
import { server } from '../../constants';
import axios from 'axios'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function Login(props) {
    const navigate = useNavigate();
    const [registerError, setregisterError] = React.useState(false)
    const [isRegisterSuccess, setisRegisterSuccess] = React.useState(false)

    const dialogHandle = () => {
        setisRegisterSuccess(false)
        navigate("/login")
    }


    function showForm({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting
    }) {
        return (
            <form noValidate onSubmit={handleSubmit} >
                <TextField
                    required
                    fullWidth
                    value={values.username}
                    onChange={handleChange}
                    style={{ marginRight: 4,paddingBottom: 20}}
                    id="username"
                    name="username"
                    label="Username"
                    variant="outlined" />

                <TextField
                    required
                    fullWidth
                    value={values.password}
                    onChange={handleChange}
                    style={{ marginRight: 4,paddingBottom: 20}}
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    variant="outlined" />
                {registerError && <Alert severity="error">Existing Account!</Alert>}
                <Button
                    fullWidth
                    style={{ padding: 4, marginTop: 30 }}
                    variant="contained"
                    color="success"
                    type='submit'
                    disabled={isSubmitting}

                >Register</Button>
                <Button fullWidth style={{ padding: 4 }} onClick={() => {
                    navigate(-1)
                }} >Back</Button>
            </form>)
    }

    function showDialog() {
        return (
            <Dialog
                open={isRegisterSuccess}
                onClose={dialogHandle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Register status"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{width : 250}}>
                        Registration Succesful
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dialogHandle} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>)
    }



    return (
        <Card sx={{ maxWidth: 350 }} style={{ height: registerError ? 520 : 480, width: 320, display: "flex", flexDirection: "column" }} >
            <CardMedia
                component="img"
                alt="green iguana"
                height="160"
                image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
            />
            <Box fullWidth style={{ padding: 5, margin: 10 }} >
                <Typography style={{ padding: 7 }} variant='h5' component='h2' > Register</Typography>
                <Formik
                    initialValues={{ username: "", password: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        console.log(server.REGISTER)
                        axios.post(server.REGISTER, values)
                            .then(
                                result => {
                                    setSubmitting(false)
                                    setregisterError(false)
                                    setisRegisterSuccess(true)

                                }
                            ).catch(err => {
                                console.log(err)
                                setregisterError(true)
                                setSubmitting(false)
                            })
                    }
                    }
                >{props => showForm(props)}</Formik>

            </Box>
            {showDialog()}
        </Card>
    );
}
