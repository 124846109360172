import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as stockAction from '../../actions/stock.action'


function findMaxLengthOfname(arr = []) {
    let max = 0
    arr.forEach((_, index, arrayList) => {
        if (arrayList[index].item_name.length > max) {
            max = arrayList[index].item_name.length
        }
    })
    console.log("max ", max)
    return parseInt(max)
}


export default function Stock() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const stockReducer = useSelector(({ stockReducer }) => stockReducer)
    const [selectionItem, setSelectionItem] = React.useState([])
    const [needFetching, setNeedFetching] = React.useState(false)


    React.useEffect(() => {
        dispatch(stockAction.getProducts())
        setNeedFetching(false)

    }, [needFetching])




    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'item_name', headerName: 'item_name', width: 130, minWidth: 300 },
        { field: 'category', headerName: 'category', width: 130 },
        { field: 'brand', headerName: 'brand', width: 130 },
        { field: 'model', headerName: 'model', width: 130 },
        { field: 'serial_number', headerName: 'serial_number', width: 130 },
        { field: 'project_name', headerName: 'project_name', width: 130 },
        { field: 'status', headerName: 'status', width: 130 },
        { field: 'borrower', headerName: 'borrower', width: 130 },
        { field: 'pv_number', headerName: 'pv_number', width: 130 },
        { field: 'po_number', headerName: 'po_number', width: 130 },
    ];


    return (

        <div style={{ width: 2500 }} >

            <div style={{ height: 700, width: 1750, borderRadius: 10, boxSizing: 0 }}>
                <Typography style={{ marginBottom: 15, borderRadius: 10 }} onClick={() => {
                    navigate("/stock-create")
                }}  >
                    <Button fullWidth variant="contained" >Create</Button>
                </Typography>


                {
                    (selectionItem.length === 1) &&
                    <Typography style={{ marginBottom: 15, borderRadius: 10 }} >
                        <Button fullWidth sx={{ bgcolor: '#ff9100', }} color="warning" variant="contained" >Edit</Button>
                    </Typography>
                }
                <DataGrid
                    style={{ backgroundColor: "#ffffff" }}
                    rows={stockReducer.result ? stockReducer.result.data : []}
                    columns={columns}
                    rowsPerPageOptions={[10, 20, 40, 100]}
                    checkboxSelection
                    columnWidth="*"
                    components={{ Toolbar: GridToolbar }}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        }
                    }}
                    onSelectionModelChange={async (selectionId) => {
                        setSelectionItem(selectionId)
                    }}

                />
                {
                    (selectionItem.length > 0) && <Typography style={{ marginBottom: 15, borderRadius: 10 }} >
                        <Button fullWidth color="error" variant="contained" onClick={async () => {
                            await dispatch(stockAction.deleteProducts(selectionItem))
                            setNeedFetching(true)
                        }}  >Delete</Button>
                    </Typography>
                }

            </div>
        </div>
    );
}
